import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Loading from '../components/Loading.jsx';
import { useOrganizationContext } from '../contexts/OrganizationContext.jsx';

export default function Signin() {
  const navigate = useNavigate();
  const location = useLocation();
  const {allowedNavigation} = useOrganizationContext();

  useEffect(() => {
    if (allowedNavigation?.length > 0) {
      const currentParams = new URLSearchParams(location.search);
      navigate(`${allowedNavigation[0].href}?${currentParams.toString()}`, { replace: true, preventScrolReset: true});
    }

  }, [allowedNavigation]);

  return (
    <Loading />
  );
}
