import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useOrganizationContext } from '../contexts/OrganizationContext.jsx';
import Loading from '../components/Loading.jsx';

export default function AnalyticsJWT() {
  const [searchParams] = useSearchParams();
  const { mbJwt } = useOrganizationContext();
  const analyticsOrigin = import.meta.env.VITE_ANALYTICS_ORIGIN;

  useEffect(() => {
    if (mbJwt) {
      const newSearchParams = new URLSearchParams(searchParams.toString());
      newSearchParams.set('jwt', mbJwt);
      newSearchParams.set('return_to', newSearchParams.get('return_to') ?? '/'); 
      window.location.replace(`${analyticsOrigin}/auth/sso?${newSearchParams.toString()}`) 
    }
  }, [mbJwt]);

  return (
    <Loading />
  );
}
