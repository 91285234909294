import { Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import OnboardingFormUsername from '../../components/OnboardingFormUsername.jsx';

const UpcomingFragment = () => (
  <div className="text-md text-gray-500 pb-4">
    Muffin Data confirms your login details
  </div>
);

const CurrentFragment = () => (
  <p className="text-md text-gray-500 pb-4">
    Muffin Data is validating your credentials. We'll let you know when its ready.
  </p>
);

const CompleteFragment = () => (
  <p className="text-md text-gray-500 pb-4">
    Muffin Data has validated your credentials and is ready to sync your data.
  </p>
);

const stepsFrontendData = [
  {
    name: 'Credential Capture',
    description: {},
    href: '#',
    step_id: 0
  },
  { 
    name: 'Credential Validation',
    description: {
      upcoming: <UpcomingFragment />,
      current: <CurrentFragment />,
      complete: <CompleteFragment />, 
    },
    href: '#',
    step_id: 1,
  },
];

// its not enough to have a customer step string for each status we also need the ability to define arbitrary html/css
export default function Kehe() {
  const { state } = useLocation();
  const connection_id = state?.connection_id;

  return (
    <>
        <main>
          <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
            <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900 mt-2">Connect myUNFI to Muffin Data</h1>
            <p className="mt-6">
              Muffin Data connects to your myUNFI shipments and inventory data via the <b> <a target="_blank" rel="noopener noreferrer" href="https://myunfi.com">myUNFI</a></b> portal. Follow the instructions here to associate Muffin Data to your myUNFI account and get connected. We'll use this connection to perform an initial pull, then on an ongoing basis as new data appears.
            </p>
            <OnboardingFormUsername integration_name="myUNFI" stepsFrontendData={stepsFrontendData} connection_id={connection_id} />
          </div>
        </main>
    </>
    );
}
