import { Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import OnboardingFormAuthorization from '../../components/OnboardingFormAuthorization.jsx';
import TargetImage1 from "../../assets/target_onboarding/target-1.png";
import TargetImage2 from "../../assets/target_onboarding/target-2.png";
import TargetImage3 from "../../assets/target_onboarding/target-3.png";
import TargetImage4 from "../../assets/target_onboarding/target-4.png";
import TargetImage5 from "../../assets/target_onboarding/target-5.png";
import TargetImage6 from "../../assets/target_onboarding/target-6.png";
import TargetImage7 from "../../assets/target_onboarding/target-7.png";
import TargetImage8 from "../../assets/target_onboarding/target-8.png";
import TargetImage9 from "../../assets/target_onboarding/target-9.png";
import TargetImage10 from "../../assets/target_onboarding/target-10.png";

const Step0UpcomingFragment = () => null;

const Step0CurrentFragment = () => (
  <div className="text-md text-gray-500 pb-4">
    <h2 className="text-xl font-bold leading-tight tracking-tight lg:pb-8">Request a relationship with Muffin Data</h2>
    You can use <a className="underline text-blue-600" href="https://logonservices.oauth.iam.partnersonline.com/" target="_blank">this link</a> to Log in to <span className="font-blold">Partners Online</span> with your email and password. Once logged in, navigate to <span className="font-bold">Vendor Management and Maintenance (VMM)</span> and then to <span className="font-bold">Relationships + Referrals</span>.
    <img className="mt-2 object-scale-up w-[20rem] pt-4 pb-4" src={TargetImage1}/>
    On the <span className="font-bold">Relationships + Referrals</span> screen, select <span className="font-bold">Request New Relationship</span> as below.
    <img className="mt-2 object-scale-up w-[20rem] pt-4 pb-4" src={TargetImage2}/>
    In the form fields that follow, select that your company is the <span className="font-bold">Merchandise Vendor</span>, and that the company you wish to create a relationship with is the <span className="font-bold">Sales Representative</span>.
    <img className="mt-2 object-scale-up w-[20rem] pt-4 pb-4" src={TargetImage3}/>
    <img className="mt-2 object-scale-up w-[20rem] pt-4 pb-4" src={TargetImage4}/>
    When you reach the form field for the <span className="font-bold">Related Partner Relationship Key</span>, enter Muffin Data's key, <span className="font-bold">13MP0Uz</span>, like so:
    <img className="mt-2 object-scale-up w-[40rem] pt-4 pb-4" src={TargetImage5}/>
    Finally, hit <span className="font-bold">Submit</span>. This grants Muffin Data the ability to accept the relationship.
  </div>
);

const Step0CompleteFragment = () => (
  <p className="text-md text-gray-500 pb-4">
    You have requested a relationship with Muffin Data in Target's portal.
  </p>
);
const Step1UpcomingFragment = () => (
  <div className="text-md text-gray-500 pb-4">
    In this step, Muffin Data will accept the relationship you requested in the previous step.
  </div>
);

const Step1CurrentFragment = () => (
  <p className="text-md text-gray-500 pb-4">
    Muffin Data is working on accepting the relationship and will let you know when its complete.
  </p>
);

const Step1CompleteFragment = () => (
  <p className="text-md text-gray-500 pb-4">
    Muffin Data has accepted your relationship.
  </p>
);

const Step2UpcomingFragment = () => (
  <div className="text-md text-gray-500 pb-4">
    At this step, you will need to update Muffin Data's access to make sure we are able to view the relevant reporting.
  </div>
);

const Step2CurrentFragment = () => (
  <div className="text-md text-gray-500 pb-4">
    Once Muffin Data has accepted the relationship request, our contact information will be shared with your company. In Partners Online, navigate again to <span className="font-bold">Vendor Management and Maintenance (VMM)</span> and then to <span className="font-bold">Contacts + Permissions</span>.
    Find <span className="font-bold">Recalde, Andres</span> in the list and click that name as below:
    <img className="mt-2 object-scale-up w-[60rem] pt-4 pb-4" src={TargetImage6}/>
    Within the user profile, select <span className="font-bold">View User Details</span>. If the user doesn't have access to any partner-controlled applications, click <span className="font-bold">Update Access</span> to ensure Muffin Data has access to all relevant reporting.
    <img className="mt-2 object-scale-up w-[40rem] pt-4 pb-4" src={TargetImage7}/>
    <img className="mt-2 object-scale-up w-[40rem] pt-4 pb-4" src={TargetImage8}/>
    On the next screen, select the radio button for <span className="font-bold">Recalde, Andres</span> and click <span className="font-bold">Select</span> as below:
    <img className="mt-2 object-scale-up w-[60rem] pt-4 pb-4" src={TargetImage9}/>
    Then, select <span className="font-bold">Add a provisioning rule</span> and ensure each of the following boxes are checked:
    <img className="mt-2 object-scale-up w-[40rem] pt-4 pb-4" src={TargetImage10}/>
    Finally, click <span className="font-bold">Select</span>. That’s it! We’ll be able to detect a new connection shortly after this step is completed.
  </div>
);

const Step2CompleteFragment = () => (
  <p className="text-md text-gray-500 pb-4">
    Access and provisioning step complete!
  </p>
);
const Step3UpcomingFragment = () => (
  <div className="text-md text-gray-500 pb-4">
    Muffin Data will verify the integration setup.
  </div>
);

const Step3CurrentFragment = () => (
  <p className="text-md text-gray-500 pb-4">
    Muffin Data is verifying the integration is setup correctly. We will notify you when its ready begin syncing data.
  </p>
);

const Step3CompleteFragment = () => (
  <p className="text-md text-gray-500 pb-4">
    Access and provisioning step complete!
  </p>
);


const stepsFrontendData = [
  {
    name: 'Create a relationship with Muffin Data',
    description: {
      upcoming: <Step0UpcomingFragment />,
      current: <Step0CurrentFragment />,
      complete: <Step0CompleteFragment />, 
    },
    href: '#',
    step_id: 0
  },
  { 
    name: 'Muffin Data accepts the relationship',
    description: {
      upcoming: <Step1UpcomingFragment />,
      current: <Step1CurrentFragment />,
      complete: <Step1CompleteFragment />, 
    },
    href: '#',
    step_id: 1,
  },
  { 
    name: "Update Muffin Data's access",
    description: {
      upcoming: <Step2UpcomingFragment />,
      current: <Step2CurrentFragment />,
      complete: <Step2CompleteFragment />, 
    },
    href: '#',
    step_id: 2,
  },
  { 
    name: "Muffin Data verifies setup",
    description: {
      upcoming: <Step3UpcomingFragment />,
      current: <Step3CurrentFragment />,
      complete: <Step3CompleteFragment />, 
    },
    href: '#',
    step_id: 3,
  },
];

export default function Target() {
  const { state } = useLocation();
  const connection_id = state?.connection_id;

  return (
    <>
      <main>
        <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900 mt-2">Connect Target to Muffin Data</h1>
          <p className="mt-6">
              Muffin Data connects to your Target sales and inventory data via the <b> <a target="_blank" rel="noopener noreferrer" href="https://logonservices.oauth.iam.partnersonline.com/">Target Partners Online Portal</a></b>. Follow the instructions here to associate Muffin Data to your account and get connected. We'll use this connection to perform an initial pull, then on an ongoing basis as new data appears.
            </p>
          <OnboardingFormAuthorization integration_name="Target" stepsFrontendData={stepsFrontendData} connection_id={connection_id} />
        </div>
      </main>
    </>
  );
}
