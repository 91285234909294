import React, { createContext, useContext, useState } from 'react';
import CustomNotification from '../components/CustomNotification.jsx';
import CustomNotificationMessage from '../components/CustomNotificationMessage.jsx';

const CustomNotificationContext = createContext();

export const useNotification = () => useContext(CustomNotificationContext);

export const CustomNotificationProvider = ({ children }) => {
  const [notification, setNotification] = useState('');
  const [title, setTitle] = useState('');
  const [error, setError] = useState(false);

  const sendNotification = (title, message, error) => {
    setTitle(title);
    setNotification(message);
    setError(error);
    setTimeout(() => 
      {
        setNotification(null);
        setError(false);
      }, 5000
  ); 
  };

  return (
    <CustomNotificationContext.Provider value={{ notification, sendNotification }}>
      {children}
      {notification && <CustomNotification message={<CustomNotificationMessage title={title} message={notification} error={error} />} />}
    </CustomNotificationContext.Provider>
  );
};
