import React, { useState, useEffect } from 'react';
import GridLists from '../components/GridLists.jsx';
import '../App.css';
import { useUserContext } from '../contexts/UserContext.jsx';
import connections from '../data/connections.js';
import { supabase } from '../libs/api.js';

const IntegrationsContent = () => {
  const [integrations, setIntegrations] = useState([]);
  const [error, setError] = useState(null);
  const { currentOrganization } = useUserContext();
  const [hasActiveIntegrations, setHasActiveIntegrations] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: connectionData, error } = await supabase
          .rpc('get_enriched_connections', { _organization_id: currentOrganization });

        const validConnections = connectionData.filter((c) => connections.map((c) => c.integration_id).includes(c.integration_id));

        const activeConnections = validConnections.filter((c) => c.external_status === 'active');
        if (activeConnections.length === 0) {
          setHasActiveIntegrations(false);
        } else {
          setHasActiveIntegrations(true);
        }

        let enrichedData = validConnections.map(connection => {
          const matchingConnection = connections.find(c => c.integration_id === connection.integration_id);
          return {
            ...connection,
            ...(matchingConnection || {}),
          }
        });
        setIntegrations(enrichedData);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(error);
      }
    };
    if (currentOrganization) {
      fetchData();
    }
  }, [currentOrganization]); // Empty dependency array means this effect runs once after the initial render

  const initializeConnection = async(integration_id) => {
    const { data: connectionId, error } = await supabase
      .rpc('initialize_connection', { _organization_id: currentOrganization ,_integration_id: integration_id})
    if (error) {
      console.error('Error inserting data', error);
      return false;
    }
    // console.log('created connection ID ', connectionId)
    const updatedIntegrations = integrations.map((integration) => {
      if (integration.integration_id === integration_id) {
        return {
          ...integration,
          connection_id: connectionId,
        }
      } else {
        return integration;
      }
    });
    setIntegrations(updatedIntegrations);
    return true;
  };
  const integrationsToSetup = integrations.filter((i) => i.external_status !== "active");
  const integrationsSetup = integrations.filter((i) => i.external_status === "active");
  return (
    <>
      <div className="py-10">
        <main>
          { integrationsToSetup.length > 0 ?
            <>
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900 lg:pb-8">Connect a New Source Integration</h1>
            </div>
            <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
                <GridLists is_active={false} integrations={integrationsToSetup} initializeConnection={initializeConnection} /></div>
                </>
            : null
          }
          {hasActiveIntegrations && integrationsSetup.length > 0 && (
            <div className="mx-auto max-w-7xl sm:px-6 lg:px-8 mt-10">
              <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900 lg:pb-8">Active Data Sources</h1>
              <GridLists is_active={false} integrations={integrationsSetup} initializeConnection={initializeConnection} />
            </div>
          )}
        </main>
      </div>
    </>
  );
};

export default function Integrations() {
  return (
    <IntegrationsContent />
  );
}
