import { Fragment, useState, useEffect } from 'react';
import { useNotification } from '../contexts/CustomNotificationContext.jsx';
import { useNavigate } from 'react-router-dom';
import { buttonStyle} from '../libs/sharedStyles.js';
import { primaryButtonClasses, secondaryButtonClasses } from '../libs/sharedClassNames.js';
import HotglueConfig from '@hotglue/widget';
import { useHotglue } from '@hotglue/widget';
import { useOrganizationContext } from '../contexts/OrganizationContext.jsx';
import { supabase } from "../libs/api";
import Loading from './Loading.jsx';

export default function OnboardingFormHotglue(props) {

    return (
        <HotglueConfig
        config={{
          apiKey: 'F30ISCXzyQeuflGJkifLay6EyRXBoHN2BbZyOvC9',
          envId: 'prod.hotglue.muffindata.com'
        }}>
        <HotglueForm {...props} />
      </HotglueConfig>
    );
}

function HotglueForm(props) {
  const { organization, hgJwt, updateHgJwt } = useOrganizationContext();
  const { sendNotification } = useNotification();
  const navigate = useNavigate();
  const { link, setOptions, getAvailableSources, getLinkedSources, getAvailableTargets, getLinkedTargets } = useHotglue();
  const [components, setComponents] = useState([]);
  const [source, setSource] = useState(null);
  const { connection_id, tap, target, defaultValues, flow_id } = props;
  const [connectUiParams, setConnectUiParams] = useState({});
  const [loading, setLoading] = useState(true);
  const [connecting, setConnecting] = useState(false);
  const tenant = organization?.org_details?.internal_name;
  const [linked, setLinked] = useState('');
  const dv = defaultValues ?? {};
  const buttonText = () => {
    const t = source.type;
    const exists = !!linked;
    switch (true) {
      case t === 'oauth' && connecting : return 'Authorizing...';
      case t === 'oauth' && exists: return 'Reauthorize';
      case t === 'oauth' : return 'Authorize';
      case connecting && exists : return 'Updating...';
      case connecting : return 'Connecting...';
      case exists : return 'Update';
      default : return 'Connect';
    }
  };

    useEffect(() => {

        const getComponents = async() => {
              let available;
              let src;
              if (tap) {
                available = await getAvailableSources();
                src = available.find(source => source.tap === tap);
              } else {
                available = await getAvailableTargets();
                src = available.find(source => source.target === target);
              }
              
              setSource(src);
              const uiParams = src.connect_ui_params;
              const params = Object.keys(uiParams).map((k) => {
              const comp = {...uiParams[k], id: k}
                  if (comp.type === 'select') {
                      comp.options = comp.options.map((opt) => ({key: opt.name, value: opt.value, label: opt.name}))
                      comp.defaultOption = comp.options.find(opt => 
                          opt.key === dv[comp.id]
                      );
                  };
                  return comp;
              });
              setComponents(params);
              setLoading(false);
        }

        const getLinked = async() => {
          let linked;
          if (tap) {
            linked = await getLinkedSources(flow_id, tenant);
          } else {
            linked = await getLinkedTargets(flow_id, tenant);
          }
          if (linked && linked[0] && linked[0].hasOwnProperty('config')) {
            setLinked(linked[0].config);
          }
        }
        if ( !source ){
          getComponents();
        }
        
        if ( hgJwt ){
          setOptions({jwtToken: hgJwt});
          if (!linked){
            getLinked(); 
          } 
        }
        
    }
    , [hgJwt]);
    

  const updateCDSState = async(is_setup) => {
    const { data, error } = await supabase
    .rpc('update_connection_setup', { _connection_id: connection_id, _is_setup: is_setup });
    if (error) {
        console.error('Error updating connection setup state', error);
        // TODO send error notification
        setConnecting(false);
        return;
    }
    setConnecting(false);
  }

  const onChange = (e) => {
    setConnectUiParams({...connectUiParams, [e.target.id]: e.target.value});
    };

  const handleSubmit =  async(event) => {
    setConnecting(true);
    event.preventDefault();
    link(tenant, flow_id, tap ?? target, false, {
      jwtToken: hgJwt,
      isTarget: !tap,
      checkLink: false,
      listener: {
          onPopupClose: (id, flow_id) => {
              console.log('popup closed');
          },
          onSourceLinkFailed: (tap, flow, tenant, message) => {
              console.log('source link failed');
              sendNotification('Linking Failed!', message, true);
              updateCDSState(false);
          },
          onSourceLinkCanceled: (source, flow_id) => {
            console.log('source link canceled');
            sendNotification('Linking Cancelled', "Try again when you're ready.", true);
            setConnecting(false);
          },
          onSourceLinked: (connector, flow) => {
              console.log('source linked', connector, flow);
              sendNotification('Successfully Linked!', "We'll begin pulling data shortly.");
              updateCDSState(true);
          },
          onTargetLinkFailed: (tap, flow, tenant, message) => {
            console.log('target link failed');
            sendNotification('Linking Failed!', message, true);
            setConnecting(false);
          },
          onTargetLinkCanceled: (source, flow_id) => {
            console.log('target link cancelled');
            sendNotification('Linking Cancelled', "Try again when you're ready.", true);
            setConnecting(false);
          },
          onTargetLinked: (connector, flow_id) => {
            console.log('source linked', connector, flow_id);
            sendNotification('Successfully Linked!', 'Your connection will be confirmed shortly.');
            setConnecting(false);
          }
      },
      connectValues: linked ? connectUiParams : {...dv, ...connectUiParams}
    });
  };

  return (
    <div className="flow-root mt-5">
        {loading ? <Loading />: 

        <form className="space-y-6" onSubmit={handleSubmit}>

            {components.map((comp) => (
                <div key={comp.id}>
                <label htmlFor={comp.id} className="block text-md font-medium text-gray-700 pt-8">{comp.label}</label>
                { comp.description && <p className="text-sm text-gray-500">{comp.description}</p>}
                { comp.type === 'select' ?
                <select 
                    id={comp.id}
                    name={comp.id}
                    defaultValue={dv[comp.id]}
                    onChange={onChange}
                >
                    {comp.options.map((opt) => (
                        <option key={opt.key} value={opt.value}>{opt.label}</option>
                    ))}
                </select>
                :
                <input 
                    type={comp.type} 
                    name={comp.id}
                    id={comp.id}
                    autoComplete="off" 
                    required 
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 med:text-med px-3 py-2" 
                    placeholder={comp.type == 'password' && linked[comp.id] ? "(update existing)" : linked[comp.id] ?? comp.label}
                    value={dv[comp.id]} 
                    onChange={onChange}
                />
                        
                }
                </div>
                

            ))}
            <button type="submit" className={primaryButtonClasses} style={buttonStyle}>{buttonText()}</button>

        </form>
        }
    </div>
  )
};
