import OnboardingFormHotglue from '../../components/OnboardingFormHotglue.jsx';

export default function BigQuery() {
  return (
    <>
      <main>
        <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900 mt-2">Connect Muffin Data to Google BigQuery</h1>
            <p className="mt-6">
              Export your Muffin Data to Google BigQuery
            </p>
            <OnboardingFormHotglue target='bigquery' flow_id='pwqI1FZ23' defaultValues={{uri: 'US'}}/>
        </div>
      </main>
    </>
  );
}
