import { Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import OnboardingFormAuthorization from '../../components/OnboardingFormAuthorization.jsx';
import UNFIOnboardingImage1 from '../../assets/unfi_onboarding/unfi-1.png';
import UNFIOnboardingImage2 from '../../assets/unfi_onboarding/unfi-2.png';
import UNFIOnboardingImage3 from '../../assets/unfi_onboarding/unfi-3.png';
import UNFIOnboardingImage4 from '../../assets/unfi_onboarding/unfi-4.png';
import UNFIOnboardingImage5 from '../../assets/unfi_onboarding/unfi-5.jpg';

const Step0UpcomingFragment = () => null;

const Step0CurrentFragment = () => (
  <div className="text-md text-gray-500 pb-4">
    <h2 className="text-xl font-bold leading-tight tracking-tight lg:pb-8"> Add Muffin Data as an associated user</h2>
    <p> Log into the <b> <a target="_blank" href="https://suppliers.unfi.com/Account/Login?ReturnUrl=%2FAccount%2FMyAccount">Supplier Portal</a></b> with your email and password. Once logged in, click <span className="font-bold">Suppliers</span> on the left and as the <span className="font-bold">Supplier Owner</span> click the <span className="font-bold">Supplier</span> you want to give Muffin Data access to.</p>
    <img className="mt-2 object-scale-down w-[60rem] pt-4 pb-4" src={UNFIOnboardingImage1} />

    <p className="mt-3">
      Click on the <span className="font-bold">Associated Users</span> tab and press <span className="font-bold">Add (New or Existing)</span> to add a new user.
    </p>
    <img className="mt-2 object-scale-down w-[60rem] pt-4 pb-4" src={UNFIOnboardingImage2} />

    <p className="mt-4">
      Add a new user with the following information in the required fields and continue:
      <ul className="list-disc ml-5">
        <li><span className="font-bold">Email:</span> andres@muffindata.com</li>
        <li><span className="font-bold">First Name:</span> Andres</li>
        <li><span className="font-bold">Last Name:</span> Recalde</li>
        <li><span className="font-bold">Global Role:</span> Broker</li>
      </ul>
    <img className="mt-2 object-scale-down w-[60rem] pt-4 pb-4" src={UNFIOnboardingImage3} />
      <p className="mt-4">
        Under Company Relationships, add the following:
        <ul className="list-disc ml-5">
          <li><span className="font-bold">Company Roles:</span> Reporter</li>
          <li><span className="font-bold">Contact Types:</span> Broker</li>
        </ul>
      <img className="mt-2 object-scale-down w-[60rem] pt-4 pb-4" src={UNFIOnboardingImage4} />
        <span className="font-bold">Save</span>. That’s it! We’ll be able to detect a new connection shortly after this step is completed.
      <img className="mt-2 object-scale-down w-[60rem] pt-4 pb-4" src={UNFIOnboardingImage5} />
      </p>
    </p>
  </div>
);

const Step0CompleteFragment = () => (
  <div className="text-md text-gray-500 pb-4">
    <h2 className="text-xl font-bold leading-tight tracking-tight lg:pb-8">Add Muffin Data as a user</h2>
    <p>
      You've completed all the steps necessary to add Muffin Data as a user!
    </p>
  </div>
);
const Step1UpcomingFragment = () => (
  <div className="text-md text-gray-500 pb-4">
    <h2 className="text-xl font-bold leading-tight tracking-tight lg:pb-8">Muffin Data verifies access</h2>
    In this step, Muffin Data will verify its user has been added correctly. 
  </div>
);

const Step1CurrentFragment = () => (
  <div className="text-md text-gray-500 pb-4">
    <h2 className="text-xl font-bold leading-tight tracking-tight lg:pb-8">Muffin Data verifies access</h2>
    Muffin Data is verifying its user is able to pull data. We will let you know when its ready.
  </div>
);

const Step1CompleteFragment = () => (
  <p className="text-md text-gray-500 pb-4">
    <h2 className="text-xl font-bold leading-tight tracking-tight lg:pb-8">Muffin Data has verified access</h2>
    Muffin Data's user has been verified and is ready to pull data.
  </p>
);

const stepsFrontendData = [
  {
    name: 'Authorize Muffin Data User',
    description: {
      upcoming: <Step0UpcomingFragment />,
      current: <Step0CurrentFragment />,
      complete: <Step0CompleteFragment />, 
    },
    href: '#',
    step_id: 0
  },
  { 
    name: 'Muffin Data verifies the authorization',
    description: {
      upcoming: <Step1UpcomingFragment />,
      current: <Step1CurrentFragment />,
      complete: <Step1CompleteFragment />, 
    },
    href: '#',
    step_id: 1,
  },
];

export default function UNFI() {
  const { state } = useLocation();
  const connection_id = state?.connection_id;

  return (
    <>
        <main>
          <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
            <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900 mt-2">Connect UNFI to Muffin Data</h1>
            <p className="mt-6">
              Muffin Data connects to your UNFI shipments and inventory data via the <b> <a target="_blank" rel="noopener noreferrer" href="https://suppliers.unfi.com/Account/Login?ReturnUrl=%2FAccount%2FMyAccount">UNFI Supplier Portal</a></b> for users with SIS or ClearVue access. Follow the instructions here to associate Muffin Data to your UNFI account and get connected. We'll use this connection to perform an initial pull, then on an ongoing basis as new data appears.
            </p>
            <OnboardingFormAuthorization integration_name="UNFI" stepsFrontendData={stepsFrontendData} connection_id={connection_id} />
          </div>
        </main>
    </>
  );
}
