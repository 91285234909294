export default function NotFoundPage() {
  return (
    <>
      <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
        <div className="text-center">
          <p className="text-3xl font-semibold text-primaryBrandColor">404</p>
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Page not found</h1>
          <p className="mt-6 text-base leading-7 text-gray-600">Sorry, we couldn’t find the page you’re looking for.</p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <a
              href="/"
              style={{
                boxShadow: "3px 3px 0 0 #3d5b2b"
              }}
              className="shadow-md flex w-full text-primaryBrandColor border-2 border-primaryBrandColor justify-center rounded-md bg-secondaryBrandColor px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-brandPeeledBananaYellow focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primaryBrandColor shadow-buttonShadowPrimary"
            >
              <span className="text-primaryBrandColor">Go back home</span>
            </a>
            <a href="mailto:founders@muffindata.com" className="text-sm font-semibold text-gray-900">
              Contact support <span aria-hidden="true">&rarr;</span>
            </a>
          </div>
        </div>
      </main>
    </>
  )
}

