import { Fragment, useState, useEffect } from 'react';
import { CheckIcon, PlayIcon, PauseIcon, ClockIcon, HandRaisedIcon, ArrowPathRoundedSquareIcon, EllipsisHorizontalCircleIcon, ChevronDoubleDownIcon } from '@heroicons/react/20/solid'
import { useNotification } from '../contexts/CustomNotificationContext.jsx';
import { useUserContext } from '../contexts/UserContext.jsx';
import { encryptWithPublicKey } from '../libs/utils.js';
import { fetchSteps, fetchCOSI } from '../libs/fetch.js';
import OnboardingIcon from './OnboardingIcon.jsx';
import { useNavigate } from 'react-router-dom';
import { buttonStyle} from '../libs/sharedStyles.js';
import { primaryButtonClasses, secondaryButtonClasses } from '../libs/sharedClassNames.js';
import { supabase } from '../libs/api.js';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function OnboardingFormAuthorization(props) {
  const { sendNotification } = useNotification();
  const { user } = useUserContext();
  const navigate = useNavigate();

  const [steps, setSteps] = useState([]);
  const [currentStep, setCurrentStep] = useState({});
  const [cosis, setCOSIs] = useState([]);

  const loadSteps = async () => {
    const s = await fetchSteps(props.connection_id, props.stepsFrontendData);
    const firstStep = s.find(st => st.step_id === 0);
    const findCS = s.find(st => st.status ==='current');

    const c = await fetchCOSI(props.connection_id, firstStep);
    setSteps(s);
    setCurrentStep(findCS);
    setCOSIs(c);
  };

  useEffect(() => {
    loadSteps();
  }, []);

  const handleSubmit = async(event) => {
    event.preventDefault();
    const { error } = await supabase
      .rpc('submit_input_and_update_steps', { _input: {} ,_current_step_id: currentStep.id});

    if (error) {
      console.log("error submitting COSI: ", error);
    }
    await loadSteps();
    sendNotification('Successfully submitted!', 'We will verify your authorization shortly.');
  };

  const StepButton = ({ stepStatus, owner, stepId }) => {
    if (stepStatus === 'current' && owner === 'customer') {
      return (
        <div className="flex">
          <button
            type="submit"
            style={buttonStyle}
            className={primaryButtonClasses}
          >
            Next Step
          </button>
          {stepId !== 0 ? <button
            onClick={handleReset}
            style={buttonStyle}
            className={secondaryButtonClasses}
          >
              Back to start
          </button>: null}
        </div>
      );
    } 
    else if (stepStatus === 'current' && owner ==='muffin') {
      return (

        <div>
          <button
            onClick={() => navigate('/integrations')}
            style={buttonStyle}
            className={primaryButtonClasses}>
            &larr; Back to Integrations</button>
          <button
            onClick={handleReset}
            style={buttonStyle}
            className={secondaryButtonClasses}
          >
            Restart 
          </button>
        </div>
      )

    }
    else {
      return null;
    }
  }

  const handleReset = async(event) => {
    event.preventDefault();
    const { error: resetError } = await supabase.rpc('reset_connection_onboarding_steps', { _connection_id: props.connection_id});
    await loadSteps();
  };

  return (
    <div className="flow-root mt-5">
      <ul role="list" className="-mb-8">
        {steps.sort((a,b) => a.step_id - b.step_id).map((step, stepIdx) => (
          <li key={step.step_id}>
            <div className="relative pb-8">
              {stepIdx !== steps.length - 1 ? (
                <span className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
              ) : null}
              <div className="relative flex">
                <OnboardingIcon status={step.status} />
                <div className="flex min-w-0 flex-1">
                  <div className="flex flex-1">
                    <div className="w-full ml-4 mt-1">
                      {step.description[step.status]}
                      <form className="space-y-6" onSubmit={handleSubmit}>
                        <div className="flex justify-left">
                          <StepButton stepStatus={step.status} owner={step.owner} stepId={step.step_id} />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
};
