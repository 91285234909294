import React, { createContext, useContext, useState, useEffect } from 'react';
const UserContext = createContext();

export const useUserContext = () => useContext(UserContext);
export const UserProvider = props => {
  return (
    <UserContext.Provider value={props.value}>
      {props.children}
    </UserContext.Provider>
  );
};
