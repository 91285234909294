import { Fragment, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { primaryButtonClasses, secondaryButtonClasses } from '../../libs/sharedClassNames.js';
import { buttonStyle} from '../../libs/sharedStyles.js';
const stepsFrontendData = [];
import OnboardingFormHotglue from '../../components/OnboardingFormHotglue.jsx';

import { useOrganizationContext } from '../../contexts/OrganizationContext.jsx';

export default function Faire() {
  const { state } = useLocation();
  const connection_id = state?.connection_id;

  return (
    <>
      <main>
        <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900 mt-2">Connect Faire to Muffin Data</h1>
            <p className="mt-6">
              Muffin Data connects to your Faire sales data via their API.
            </p>
            <OnboardingFormHotglue connection_id={connection_id} tap='faire' flow_id='nafPBxBEu' defaultValues={{uri: 'US'}}/>
        </div>
      </main>
    </>
  );
}
