import AmazonSCImage from '../assets/amazon_small.png';
import AmazonVCImage from '../assets/amazon_small.png';
import FaireRoundImage from '../assets/faire_round.png';
import KeHERoundImage from '../assets/kehe_round.jpeg';
import CostcoRoundImage from '../assets/costco_round.jpg';
import SPINSRoundImage from '../assets/spins_round.png';
import KrogerRoundImage from '../assets/kroger_round.png';
import TargetRoundImage from '../assets/target_round.png';
import UNFIRoundImage from '../assets/unfi_round.png';
import WalmartRoundImage from '../assets/walmart_round.png';
import WegmansRoundImage from '../assets/wegmans_round.png';
import WholeFoodsRoundImage from "../assets/whole_foods.png";
import VIPLogoRound from "../assets/vip_onboarding/vip-logo-round.png";
import ShopifyLogoRound from "../assets/shopify_round.svg";

const connections = [
  {
    name: 'Amazon Seller Central',
    title: 'Orders, item sales and other reporting.',
    imageUrl: AmazonSCImage,
    integration_id: 11
  },
  {
    name: 'Amazon Vendor Central',
    title: 'Daily sales rollups, POs, and forecasts.',
    imageUrl: AmazonVCImage,
    integration_id: 17
  },
  {
    name: 'Costco',
    title: 'Daily sales and inventory.',
    imageUrl: CostcoRoundImage,
    integration_id: 15
  },
  {
    name: 'Faire',
    title: 'Orders, items, and daily rollups.',
    imageUrl: FaireRoundImage,
    integration_id: 8
  },
  {
    name: 'KeHE',
    title: 'Daily DC inventory, weekly DC shipments.',
    imageUrl: KeHERoundImage,
    integration_id: 2
  },
  {
    name: 'Kroger',
    title: 'Daily store sales and inventory.',
    imageUrl: KrogerRoundImage,
    integration_id: 7
  },
  {
    name: 'SPINS Store Insights',
    title: 'Store-level sales via Store Insights.',
    imageUrl: SPINSRoundImage,
    integration_id: 19
  },
  {
    name: 'Target',
    title: 'Store sales, DC and store inventory.',
    imageUrl: TargetRoundImage,
    integration_id: 5
  },
  {
    name: 'Wegmans',
    title: 'Daily store sales, your brand and others.',
    imageUrl: WegmansRoundImage,
    integration_id: 1
  },
  {
    name: 'UNFI Email',
    title: 'Emails we get for UNFI',
    integration_id: 21
  },
  {
    name: "BJ's Wholesale Club",
    title: "Sales data from BJ's",
    integration_id: 22
  },
  {
    name: "Whole Foods",
    title: "Sales data and product data.",
    imageUrl: WholeFoodsRoundImage,
    integration_id: 23
  },
  {
    name: "myUNFI",
    title: "Sales and inventory data.",
    integration_id: 25,
    imageUrl: UNFIRoundImage
  },
  {
    name: "SPINs",
    title: "",
    integration_id: 18
  },
  {
    name: "VIP",
    title: "Sales data from VIP",
    integration_id: 26,
    imageUrl: VIPLogoRound
  },
  {
    name: "Shopify",
    title: "Sales data from Shopify",
    integration_id: 27,
    imageUrl: ShopifyLogoRound
  },
];

export default connections;
