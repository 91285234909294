import { CheckIcon, PlayIcon, PauseIcon, ClockIcon, HandRaisedIcon, ArrowPathRoundedSquareIcon, EllipsisHorizontalCircleIcon, ChevronDoubleDownIcon } from '@heroicons/react/20/solid'

const statusToIconMap = {
  upcoming: {
    icon: HandRaisedIcon,
    iconBackground: 'bg-gray-500',
  },
  current: {
    iconBackground: 'bg-orange-400',
    icon: EllipsisHorizontalCircleIcon,
  },
  complete: {
    icon: CheckIcon,
    iconBackground: 'bg-green-700',
  }
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function OnboardingIcon(props) {
  const i = statusToIconMap[props.status];

  return (
    <span
      className={classNames(
        i.iconBackground,
        'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white'
      )}
    >
      <i.icon className="h-5 w-5 text-white" aria-hidden="true" />
    </span>
  )
};
