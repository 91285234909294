import React, { createContext, useContext, useState, useEffect } from 'react';
import { useUserContext } from './UserContext.jsx';
import { supabase } from "../libs/api";
const OrganizationContext = createContext();

export const useOrganizationContext = () => useContext(OrganizationContext);

const navigation = [
  { name: 'Analytics', href: '/analytics', id: 'analytics'},
  { name: 'Integrations', href: '/integrations', id: 'integrations' },
  { name: 'Destinations', href: '/destinations', id: 'destinations'}
];

export const OrganizationProvider = ({ children }) => {
  const { currentOrganization, setLoading } = useUserContext('');
  const [organization, setOrganization] = useState({});
  const [hgJwt, setHgJwt] =  useState(null);
  const [mbJwt, setMbJwt] =  useState(null);
  const [allowedNavigation, setAllowedNavigation] = useState([]);

  const getOrganization = async ()=>{
    if (currentOrganization) {
        const { data, error } = await supabase
        .rpc('get_enriched_organization', { _organization_id: currentOrganization });
        if (error) {
            console.error('Error fetching data:', error);
            return;
        }
        const orgData = data[0];
        const userAccess = orgData.user_access;
        setAllowedNavigation(navigation.filter(nav => userAccess[nav.id] === true));
        // orgData['default_navigation'] = orgData['allowed_navigation'][0]['href'];
        setOrganization(orgData);
    }
  }
  const updateHgJwt = async() => {
    setHgJwt(null);
    if (currentOrganization){
    const { data, error } = await supabase
    .rpc('get_organization_hg_jwt', { _organization_id: currentOrganization });
    setHgJwt(data);
    }
  }

  const updateMbJwt = async() => {
    if (currentOrganization){
    const { data, error } = await supabase
    .rpc('get_organization_mb_jwt', { _organization_id: currentOrganization });
    setMbJwt(data);
    }
  }

  useEffect(() => {
    getOrganization();
    updateHgJwt();
    updateMbJwt();

  }, [currentOrganization]);


  return (
    <OrganizationContext.Provider value={{ organization, setOrganization, hgJwt, mbJwt, allowedNavigation, updateHgJwt}}>
      {children}
    </OrganizationContext.Provider>
  );
};
