import React, { useState, useEffect } from 'react';
import '../../App.css';
import { useUserContext } from '../../contexts/UserContext.jsx';
import { useNavigate } from 'react-router-dom';
import {
  PencilSquareIcon,
  PauseCircleIcon,
  ClipboardDocumentIcon,
  BuildingOfficeIcon,
} from '@heroicons/react/20/solid';
import StatusBadge from '../../components/StatusBadge.jsx';
import GoogleBigQuery from "../../assets/GoogleBigQuery.svg";
import Snowflake from "../../assets/Snowflake.webp";
import Loading from '../../components/Loading.jsx';

const destinations = [
    {
        "name": "Google BigQuery",
        "title": "Export your data to Google BigQuery",
        "imageUrl": GoogleBigQuery,
        "path": "/destinations/bigquery"
    },
    {
        "name": "Snowflake",
        "title": "Export your data to Snowflake",
        "imageUrl": Snowflake,
        "path": "/destinations/snowflake"
    }
];

const DestinationContent = () => {

  const navigate = useNavigate();

  const [error, setError] = useState(null);
  const { currentOrganization } = useUserContext();
  const [availableDestinations, setAvailableDestinations] = useState([]);
  
  
  useEffect(() => {
    setAvailableDestinations(destinations.filter(destination => destination.name === 'Google BigQuery'));
  }, [currentOrganization]); // Empty dependency array means this effect runs once after the initial render

  if (error) {
    return <div>Error loading data</div>;
  }
  // if (!availableDestinations || availableDestinations.length === 0) {
  //   return <Loading />;
  // }


  const handleSetupLinkClick = async(e, destination) => {
    e.preventDefault();

    navigate(destination.path, { state: { ...destination }} );
  };


  return (
    <>
      {!availableDestinations || availableDestinations.length === 0 ? <Loading/> :
      <div className="py-10">
        <header>
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900 lg:pb-8">Connect a New Destination</h1>
          </div>
        </header>
        <main>
          <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
            <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
            {availableDestinations.sort((a,b) => a.name.localeCompare(b.name)).map((destination) => (
            <li key={destination.name} className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow">
                <div className="flex w-full items-center justify-between space-x-6 p-6">
                <div className="flex-1">
                    <div className="flex items-center space-x-3">
                    <h3 className="truncate text-sm font-medium text-gray-900">{destination.name}</h3>
                    <StatusBadge status={destination.external_status} />
                    </div>
                    <p className="mt-1 text-sm text-gray-500">{destination.title}</p>
                </div>
                {destination.imageUrl ? (
                    <img className="h-[6rem] w-[6rem] flex-shrink-0 rounded-full bg-gray-100" src={destination.imageUrl} alt="" />
                    ) : (
                    <BuildingOfficeIcon className="h-[6rem] w-[6rem] text-gray-200" aria-hidden="true" />
                    )}
                </div>
                <div>
                <div className="-mt-px flex divide-x divide-gray-200">
                    <div className="flex w-0 flex-1">
                    <a
                        onClick={(e) => handleSetupLinkClick(e, destination)}
                        href="#"
                        className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                    >
                        <PencilSquareIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        Set Up
                    </a>
                    </div>
                </div>
                </div>
            </li>
            ))}
            </ul>
          </div>
        </main>
      </div>
}
    </>
  );
};

export default function Destinations() {
  return (
    <DestinationContent />
  );
}
