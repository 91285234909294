import React, { useContext, useState, useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useOrganizationContext } from '../contexts/OrganizationContext.jsx';

const ExportsGuard = ()  =>{
  const { organization } = useOrganizationContext();
  const isAllowed = organization && organization?.org_details?.exports?.enabled

  if (!isAllowed) {
    return <Navigate to="/" replace />;
  }

  return <Outlet />;
}

export default ExportsGuard;
