import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserContext } from '../contexts/UserContext.jsx';
import Muffin from '../assets/primary_logo.png';
import { buttonStyle} from '../libs/sharedStyles.js';
import { primaryButtonClasses, secondaryButtonClasses } from '../libs/sharedClassNames.js';
import UnauthenticatedHeader from '../components/UnauthenticatedHeader.jsx';
import { supabase } from '../libs/api.js';
import { useNotification } from '../contexts/CustomNotificationContext.jsx';

export default function ForgotPassword() {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const { sendNotification } = useNotification();

  async function resetPassword(event) {
    event.preventDefault();
    
    if (email) {
      const { data, error } = await supabase.auth.resetPasswordForEmail(email, {
        redirectTo: 'https://app.muffindata.com/reset-password',
      });
      if (error) {
        setErrorMessage(error.message)
      } else {
        navigate('/');
      }
    }
  }

  return (
    <>
      <UnauthenticatedHeader />
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Forgot Password
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-6" onSubmit={resetPassword}>
            <div>
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  placeholder="your-email@your-domain.com"
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-700 sm:text-sm sm:leading-6 px-3 py-2"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>
            <div>
              {errorMessage && (
                <div className="text-sm font-medium text-red-600">
                  {errorMessage}
                </div>
              )}
              <button
                type="submit"
                style={buttonStyle}
                onClick={() => sendNotification('Reset email sent!', 'Check your inbox for an email from Muffin Data to reset your password')}
                className={primaryButtonClasses}
              >
                Submit
              </button>
              <button
                style={buttonStyle}
                className={secondaryButtonClasses}
                onClick={() => {
                  navigate("/signin");
                }}
              >
                Go back
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
