import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useUserContext } from '../contexts/UserContext.jsx';
import { useOrganizationContext } from '../contexts/OrganizationContext.jsx';
import Loading from '../components/Loading.jsx';

const ProtectedRoute = ()  =>{
  const { user } = useUserContext();
  const { organization } = useOrganizationContext();

  if (user === '' ) {
    return <Loading />;
  } else if (!user) {
    return <Navigate to="/signin" replace />;
  } else if (!organization) {
    return <Loading />;
  }

  return <Outlet />;
}

export default ProtectedRoute;
