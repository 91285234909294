import { Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import Header from '../../components/Header.jsx';
import { useOrganizationContext } from '../../contexts/OrganizationContext.jsx';
import OnboardingFormAuthorization from '../../components/OnboardingFormAuthorization.jsx';
import WalmartImage1 from "../../assets/walmart_onboarding/walmart-1.webp";
import WalmartImage2 from "../../assets/walmart_onboarding/walmart-2.webp";
import WalmartImage3 from "../../assets/walmart_onboarding/walmart-3.webp";

// TODO replace?
// some way to swap him out for Sal?
const firstName = 'Andrés';
const lastName = 'Recalde';

const Step0UpcomingFragment = () => (
  <div className="text-md text-gray-500 pb-4">
    <p className="text-md text-gray-500 pb-4">
      Allowing Muffin Data to access your Walmart data involves creating an account for Muffin Data to use as defined in Walmart's documentation <a href="https://supplierhelp.walmart.com/s/guide?article=000009150#add_a_user">here</a>. Note that <b>only Retail Link administrators</b> can perform this action.
    </p>
  </div>
);

const Step0CurrentFragment = ({ email, brandName }) => (
  <div className="text-md text-gray-500 pb-4">
    <h2 className="text-xl font-bold leading-tight tracking-tight lg:pb-8">Log in to Retail Link and navigate to User Management.</h2>
    You can use <a href="https://retaillink.login.wal-mart.com/">this link</a> to log in to Retail Link with your email and password.
    <img className="mt-2 object-scale-up pt-4 pb-4" src={WalmartImage1}/>
    Once logged in, navigate to <b>User Management</b> in the upper-right-hand corner.
    <img className="mt-2 object-scale-up pt-4 pb-4" src={WalmartImage2}/>
    In the <b>User Management</b> screen, select <b>Add User</b> as below:
    <img className="mt-2 object-scale-up pt-4 pb-4" src={WalmartImage3}/>
    Fill out the following form fields:
    <ul>
      <li><b>First Name:</b> {firstName}</li>
      <li><b>Last Name:</b> {lastName}</li>
      <li><b>Email:</b> {email}</li>
      <li><b>Employee Type:</b> Third Party</li>
      <li><b>What is your third-party company name? :</b> Muffin Data</li>
      <li><b>What is your third-party company type? :</b> Other</li>
      <li><b>Third-party Type:</b> Analytics Data Platform</li>
      <li><b>Employee Position:</b> Other</li>
      <li><b>Role:</b> Luminate User</li>
      <li><b>Supplier:</b> {brandName}, or the appropriate supplier(s)</li>
    </ul>
  </div>
);

const Step0CompleteFragment = () => (
  <p className="text-md text-gray-500 pb-4">
    You have successfully created a user for Muffin Data in Walmart's portal.
  </p>
);

const Step1UpcomingFragment = () => (
  <div className="text-md text-gray-500 pb-4">
    Muffin Data confirms the user was created successfully
  </div>
);

const Step1CurrentFragment = () => (
  <p className="text-md text-gray-500 pb-4">
    Muffin Data is testing access with the new user.
  </p>
);

const Step1CompleteFragment = () => (
  <p className="text-md text-gray-500 pb-4">
    Muffin Data has validated access and is ready to sync your data.
  </p>
);

const stepsFrontendData = (email, brandName) => [
  {
    name: 'Create user account for Muffin Data',
    description: {
      upcoming: <Step0UpcomingFragment />,
      current: <Step0CurrentFragment email={email} brandName={brandName} />,
      complete: <Step0CompleteFragment />,
    },
    href: '#',
    step_id: 0
  },
  {
    name: 'Muffin Data verifies the new account',
    description: {
      upcoming: <Step1UpcomingFragment />,
      current: <Step1CurrentFragment />,
      complete: <Step1CompleteFragment />,
    },
    href: '#',
    step_id: 1,
  },
];

export default function Walmart() {
  const { state } = useLocation();
  const connection_id = state?.connection_id;
  const { organization } = useOrganizationContext();
  const email = `andres+${organization.name.replace(/\s+/g, '_').replace(/[^a-zA-Z0-9_]/g, '').toLowerCase()}@muffindata.com`

  return (
    <>
        <main>
          <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
            <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900 mt-2 pt-8">Connect Walmart to Muffin Data</h1>
            <p className="mt-6">
              Muffin Data connects to your Walmart sales data via the <b> <a target="_blank" rel="noopener noreferrer" href="https://www.walmartluminate.com/login/oauth2/code/falcon">Walmart Luminate Portal</a></b> for users with Basic access. Follow the instructions here to submit your credentials and get connected. We'll use this connection to perform an initial pull, then on an ongoing basis as new data appears.
            </p>
            <OnboardingFormAuthorization integration_name="Walmart Luminate" stepsFrontendData={stepsFrontendData(email, organization.name)} connection_id={connection_id} email={email} />
          </div>
        </main>
    </>
  );
}
