import publicKeyPEM from '../data/publicKey.js';

const arrayBufferToBase64 = (buffer) => {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}


const pemToArrayBuffer = (pem) => {
  const b64 = pem.replace(/-----\w* PUBLIC KEY-----/g, '').replace(/\n/g, '');
  const binaryString = window.atob(b64);
  const bytes = new Uint8Array(binaryString.length);
  for (let i = 0; i < binaryString.length; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
}

const importPublicKey = async(pem) => {
  const keyData = pemToArrayBuffer(pem);
  const publicKey = await window.crypto.subtle.importKey(
    "spki",
    keyData,
    {
      name: "RSA-OAEP",
      hash: "SHA-256",
    },
    true,
    ["encrypt"]
  );
  return publicKey;
}


const encryptWithPublicKey = async(data) => {
  return data;
  /**
  const encodedData = new TextEncoder().encode(data);
  const publicKey = await importPublicKey(publicKeyPEM);

  const encryptedData = await window.crypto.subtle.encrypt(
    {
      name: "RSA-OAEP"
    },
    publicKey,
    encodedData
  );
  return arrayBufferToBase64(encryptedData);
  */
}

export {
  encryptWithPublicKey
};
