import { Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import OnboardingFormHotglue from '../../components/OnboardingFormHotglue.jsx';

export default function AmazonVendorCentral() {
  const { state } = useLocation();
  const connection_id = state?.connection_id;

  return (
    <>
      <main>
        <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900 mt-2">Connect Amazon Vendor Central to Muffin Data</h1>
            <p className="mt-6">
              Muffin Data connects to your Amazon Vendor Central data via their API.
            </p>
            <OnboardingFormHotglue connection_id={connection_id} tap='amazon-vendor-central' flow_id='GDHPISat0' defaultValues={{uri: 'https://vendorcentral.amazon.com'}}/>
        </div>
      </main>
    </>
  );
}
