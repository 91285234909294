import { Fragment, useState, useEffect } from 'react';
import { CheckIcon, PlayIcon, PauseIcon, ClockIcon, HandRaisedIcon, ArrowPathRoundedSquareIcon, EllipsisHorizontalCircleIcon, ChevronDoubleDownIcon } from '@heroicons/react/20/solid'
import { useNotification } from '../contexts/CustomNotificationContext.jsx';
import { useUserContext } from '../contexts/UserContext.jsx';
import { encryptWithPublicKey } from '../libs/utils.js';
import { fetchSteps, fetchCOSI } from '../libs/fetch.js';
import OnboardingIcon from './OnboardingIcon.jsx';
import { useNavigate } from 'react-router-dom';
import { buttonStyle} from '../libs/sharedStyles.js';
import { primaryButtonClasses, secondaryButtonClasses } from '../libs/sharedClassNames.js';
import { supabase } from "../libs/api";
function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function OnboardingFormUsername(props) {
  const { sendNotification } = useNotification();
  const { user } = useUserContext();
  const navigate = useNavigate();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [steps, setSteps] = useState([]);
  const [currentStep, setCurrentStep] = useState({});
  const [cosis, setCOSIs] = useState([]);

  const loadSteps = async () => {
    const s = await fetchSteps(props.connection_id, props.stepsFrontendData);
    const firstStep = s.find(st => st.step_id === 0);
    const findCS = s.find(st => st.status ==='current');

    const c = await fetchCOSI(props.connection_id, firstStep);

    if ((c !== null && c.length > 0) && firstStep.status === 'complete') {
      const latestCOSI = c.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))[0];

      setUsername(latestCOSI.input.username);
      setPassword(latestCOSI.input.password.substring(0,10)); // TODO decrypt and show?
    } else {
      setUsername('');
      setPassword('');
    }

    setSteps(s);
    setCurrentStep(findCS);
    setCOSIs(c);
  };

  useEffect(() => {
    loadSteps();
  }, []);

  const handleSubmit =  async(event) => {
    event.preventDefault();
    const encryptedPassword = await encryptWithPublicKey(password);

    const { error } = await supabase
      .rpc('submit_input_and_update_steps', { _input: { username: username, password: encryptedPassword} ,_current_step_id: currentStep.id, _user_id: user.user.id});

    if (error) {
      console.log("error submitting COSI: ", error);
    }

    await loadSteps();
    sendNotification('Successfully submitted!', 'Your integration credentials will be confirmed shortly.');
  };

  const handleReset = async(event) => {
    event.preventDefault();
    const { error: resetError } = await supabase.rpc('reset_connection_onboarding_steps', { _connection_id: props.connection_id});
    await loadSteps();
  };

  return (
    <div className="flow-root mt-5">
      <ul role="list" className="-mb-8">
        {steps.sort((a,b) => a.step_id - b.step_id).map((step, stepIdx) => (
          <li key={step.step_id}>
            <div className="relative pb-8">
              {stepIdx !== steps.length - 1 ? (
                <span className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
              ) : null}
              <div className="relative flex">
                <div>
                  <OnboardingIcon status={step.status} />
                </div>
                <div className="flex min-w-0 flex-1">
                {step.step_id === 0 ? 
                  <div className="flex flex-1">
                    <div className="w-full max-w-[32rem] ml-4 mt-1">
                      <p className="text-md text-gray-500">Add portal username and password</p>
                      <form className="space-y-6" onSubmit={handleSubmit}>
                        <div>
                          <label htmlFor="username" className="block text-md font-medium text-gray-700 pt-8">{props.integration_name} Username</label>
                          <input type="username" name="username" id="username" autoComplete="off" required className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 med:text-med px-3 py-2" placeholder="Username" onChange={(e) => setUsername(e.target.value)} value={username} />
                        </div>
                        <div>
                          <label htmlFor="password" className="block text-md font-medium text-gray-700">{props.integration_name} Password</label>
                          <input type="password" name="password" id="password" autoComplete="off" required className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 med:text-med px-3 py-2" placeholder="Password" onChange={(e) => setPassword(e.target.value)} value={password} />
                        </div>
                        <div className="flex justify-left">
                          {currentStep.id === step.id ? <button type="submit" className={primaryButtonClasses} style={buttonStyle}>Submit</button>: <button onClick={handleReset} className={primaryButtonClasses} style={buttonStyle}>Reset Credentials</button>}
                        </div>
                      </form>
                      {step.description[step.status]}
                    </div>
                  </div>:
                    <div>
                      <div className="ml-1 mt-1">
                      {step.description[step.status]}
                      {currentStep.id === step.id ? <button onClick={() => navigate('/integrations')} className={primaryButtonClasses} style={buttonStyle}>&larr; Back to Integrations</button>: null}
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
};
