export default function Redirect() {
  const { url } = window.location.search;

  useEffect(() => {
    if (url) {
      window.location.href = url;
    }
  }, [url]);

  return (
    <div>
      <p>Redirecting...</p>
    </div>
  );
}

