import React, { useState, useEffect } from 'react';
import { useUserContext } from '../contexts/UserContext.jsx';
import { useNavigate } from 'react-router-dom';
import {
  PencilSquareIcon,
  PauseCircleIcon,
  ClipboardDocumentIcon,
  BuildingOfficeIcon,
} from '@heroicons/react/20/solid';
import connections from '../data/connections.js';
import StatusBadge from './StatusBadge.jsx';
import { supabase } from '../libs/api.js';
import Loading from './Loading.jsx';

const GridLists = ({is_active, integrations, initializeConnection }) => {
  const navigate = useNavigate();
  const { currentOrganization } = useUserContext();

  if (!integrations || integrations.length === 0) {
    return <Loading />;
  }

  const handleSetupLinkClick = async(e, integration_id, connection_id) => {
    e.preventDefault();
    if (!connection_id && (!(await initializeConnection(integration_id)))) {
      return;
    }

    const { data, error } = await supabase
      .rpc('create_connection_onboarding_steps', { _integration_id: integration_id, _connection_id: connection_id})

    if (error) {
      console.error('Error inserting data', error);
      return;
    }

    navigate(`/integrations/${integration_id}`, { state: { connection_id: connection_id }} );
  };

  const handleEditLinkClick = async(e, integration_id, connection_id) => {
    e.preventDefault();
    navigate(`/integrations/${integration_id}`, { state: { connection_id: connection_id }} );
  };

  return (
    <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
      {integrations.sort((a,b) => a.name.localeCompare(b.name)).map((connection) => (
        <li key={connection.name} className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow">
          <div className="flex w-full items-center justify-between p-6">
            <div className="flex-1">
              <div className="flex items-center space-x-3">
                <h3 className="truncate text-sm font-medium text-gray-900">{connection.name}</h3>
                <StatusBadge status={connection.external_status} />
              </div>
              <p className="mt-1 text-sm text-gray-500">{connection.title}</p>
            </div>
             {connection.imageUrl ? (
                <img className="h-[6rem] w-[6rem] flex-shrink-0 rounded-full bg-gray-100" src={connection.imageUrl} alt="" />
              ) : (
                <BuildingOfficeIcon className="h-[6rem] w-[6rem] text-gray-200" aria-hidden="true" />
              )}
          </div>
          <div>
            <div className="-mt-px flex divide-x divide-gray-200">
              { (connection.cos_count > 0 || connection.external_status === "active") ?
              <div className="flex w-0 flex-1">
                <a
                  onClick={(e) => handleEditLinkClick(e, connection.integration_id, connection.connection_id)}
                  href="#"
                  className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                >
                  <PencilSquareIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    Edit
                </a>
              </div>:
              <div className="flex w-0 flex-1">
                <a
                  onClick={(e) => handleSetupLinkClick(e, connection.integration_id, connection.connection_id)}
                  href="#"
                  className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                >
                  <PencilSquareIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  Set Up Integration
                </a>
              </div>
              }
            </div>
          </div>
        </li>
      ))}
    </ul>
  )
};

export default GridLists;
