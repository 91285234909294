import React, { useState, useEffect } from 'react';
import '../../App.css';
import { useOrganizationContext } from '../../contexts/OrganizationContext.jsx';
import Loading from '../../components/Loading.jsx';

const AnalyticsContent = () => {
  const { mbJwt } = useOrganizationContext();
  const [iframeUrl, setIframeUrl] = useState('');
  const [iframeLoading, setIframeLoading] = useState(true);
  const analyticsOrigin = import.meta.env.VITE_ANALYTICS_ORIGIN;
  const defaultParams = new URLSearchParams({
    logo: 'false',
    top_nav: 'true',
    search: 'true',
    new_button: 'true',
  });

  useEffect(() => {
    const callback = (event) => {
      const metabaseEvent = event?.data?.metabase;
      document.body.style.overflow = 'hidden';

      if (metabaseEvent && metabaseEvent.type === 'location' && metabaseEvent.location?.pathname !== '/') {
        const newIframeUrl = `${metabaseEvent.location.pathname}${metabaseEvent.location.search}${metabaseEvent.location.hash}`;

        // don't save questions
        if (!metabaseEvent.location.pathname.includes('question')) {
          sessionStorage.setItem('returnTo', newIframeUrl);
        }
      }
    };

    window.addEventListener('message', callback);

    const savedIframeUrl = sessionStorage.getItem('returnTo');

    let finalIframeUrl = '';
    if (savedIframeUrl && mbJwt) {
      const returnTo = savedIframeUrl.includes('?') ? `${savedIframeUrl}&${defaultParams}` : `${savedIframeUrl}?${defaultParams}`;
      finalIframeUrl = `${analyticsOrigin}/auth/sso?jwt=${mbJwt}&return_to=${encodeURIComponent(returnTo)}`;
    } else {
      const returnTo = `/?${defaultParams}`;
      finalIframeUrl = `${analyticsOrigin}/auth/sso?jwt=${mbJwt}&return_to=${encodeURIComponent(returnTo)}`;
      sessionStorage.setItem('returnTo', '/');
    }

    setIframeUrl(finalIframeUrl);

    return () => {
      window.removeEventListener('message', callback);
      document.body.style.overflow = 'auto';
    };
  }, [mbJwt]);

  const handleIframeError = () => {
    const fallbackUrl = `${analyticsOrigin}/auth/sso?jwt=${mbJwt}&return_to=${encodeURIComponent(`/?${defaultParams}`)}`;
    setIframeUrl(fallbackUrl);
    sessionStorage.setItem('returnTo', '/');
  };

  return (
    <>
      {!mbJwt ? <Loading /> :
        <>
          {iframeLoading ? <Loading /> : null}
          <iframe
            src={iframeUrl}
            width="100%"
            style={{ height: '92vh', display: iframeLoading ? 'none' : 'block' }}
            onLoad={() => setIframeLoading(false)}
            onError={handleIframeError}
          ></iframe>
        </>
      }
    </>
  );
};

export default function Analytics() {
  return (
    <AnalyticsContent />
  );
}

